import React from 'react'
import { useHistory, useLocation } from "react-router-dom"
import { Navigation } from 'react-minimal-side-navigation'
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css'
import DashboardIcon from '@material-ui/icons/Dashboard'
import TocIcon from '@material-ui/icons/Toc'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import SettingsIcon from '@material-ui/icons/Settings'
import { messages } from '../../../translations/messages'
import { useIntl } from "react-intl"


const Menu = () => {
    let history = useHistory();
    let location = useLocation();
    let intl = useIntl();
    return (
        <div className="menu_area">
            <>
                <Navigation
                    // you can use your own router's api to get pathname
                    activeItemId={location.pathname}
                    onSelect={({ itemId }) => {
                        history.push(itemId);
                    }}
                    items={[
                        {
                            title: intl.formatMessage(messages.DASHBOARD),
                            itemId: '/',
                            elemBefore: () => <DashboardIcon />,
                        },
                        {
                            title: intl.formatMessage(messages.CATALOG),
                            itemId: '/catalog',
                            elemBefore: () => <TocIcon />,
                            subNav: [
                                {
                                    title: intl.formatMessage(messages.CONSIGNMENT_LIST),
                                    itemId: '/catalog/consignment/list',
                                }
                            ],
                        },
                        {
                            title: intl.formatMessage(messages.VISA),
                            itemId: '/visa',
                            elemBefore: () => <SupervisorAccountIcon />,
                            subNav: [
                                {
                                    title: intl.formatMessage(messages.COMPANY_LIST),
                                    itemId: '/visa/company/list',
                                },
                                {
                                    title: intl.formatMessage(messages.USERS),
                                    itemId: '/visa/user/list',
                                },
                                {
                                    title: intl.formatMessage(messages.PENDING_APPROVAL_USERS),
                                    itemId: '/visa/pendingapprovaluser/list',
                                }
                            ],
                        },
                        {
                            title: intl.formatMessage(messages.CONFIGURATION),
                            itemId: '/configuration',
                            elemBefore: () => <SettingsIcon />,
                            subNav: [
                                {
                                    title: intl.formatMessage(messages.DISTRICT_PLATE_LIST),
                                    itemId: '/configuration/district-plate/list',
                                }
                            ],
                        }
                    ]}
                />
            </>
        </div>
    )
}

export default Menu